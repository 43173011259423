import Vue from "vue";
import Vuex from "vuex";
import login from "./login";
import user from "./user";
import ascription from "./ascription";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters:{},
  modules: {
    login,
    user,
    ascription
  }
});
