const state = {
    // user : window.sessionStorage.getItem('user') || '登陆',
    // user_type : window.sessionStorage.getItem('user_type') || -1,
    // store_name: window.sessionStorage.getItem('store_name') || '店铺',
    // m_id:window.sessionStorage.getItem('m_id') || '',
    // p_id:window.sessionStorage.getItem('p_id') || '',
    // u_id:window.sessionStorage.getItem('u_id') || '',
    // isbind:window.sessionStorage.getItem('isbind') || 'false',
    // phone:window.sessionStorage.getItem('phone') || 1,
    // goodstype:window.sessionStorage.getItem('goodstype'),
    // vir_id:window.sessionStorage.getItem('vir_id') ||'',
    // sub_status:window.sessionStorage.getItem('sub_status') ||'',
    // sub_power:window.sessionStorage.getItem('sub_power') ||'',
    // real_name:window.sessionStorage.getItem('name') || 1,
    // fid:window.sessionStorage.getItem('fid') ||'',
    // sub_id:window.sessionStorage.getItem('sub_id') ||-1,
    // refresh:false,
    // reg_time:window.sessionStorage.getItem('reg_time') ||'',
    // stock_remind:window.sessionStorage.getItem('stock_remind') ||'',
    user: '登陆',
    user_type: -1,
    store_name: '店铺',
    m_id: '',
    p_id: '',
    u_id: '',
    isbind: 'false',
    phone: 1,
    goodstype: '',
    vir_id: '',
    sub_status: '',
    sub_power: '',
    real_name: 1,
    fid: '',
    sub_id: -1,
    refresh: false,
    reg_time: '',
    stock_remind: '',
    a_s_show: '',
    activename: '',
    lsxd_goods_open:'',
    power_open:'',
    dataVal:{
        power_open:'',
    },

    z_url: "http://xuni.chengmeiyoupin.com",
    q_url: "http://fictitious.chengmeiyoupin.com",
    z2_url: "http://kami.chengmeiyoupin.com",
    dyhcs: 'http://vvvvv.chengmeiyoupin.com/virtual/lianhaikeji/checkToken',
    dyhcs2: "http://vvvvv.chengmeiyoupin.com/virtual/lianhaikeji/setToken",
    
    // z_url: "https://xnadmin.chengmeiyouxuan.com",    
    // q_url: "https://xuniapi.chengmeiyouxuan.com",
    // z2_url: "https://kami.chengmeiyouxuan.com",
    // dyhcs: "https://vvvvv.server.chengmeiyouxuan.com/virtual/lianhaikeji/checkToken",
    // dyhcs2: "https://vvvvv.server.chengmeiyouxuan.com/virtual/lianhaikeji/setToken",
};

const getters = {
    getuser(state) {
        return state.user;
    },
    gettype(state) {
        return state.user_type;
    }
}
const actions = {};

const mutations = {
    user_info(state, payload) {
        // window.sessionStorage.setItem('user',payload);
        state.user = payload;
    },
    u_type(state, payload) {
        // window.sessionStorage.setItem('user_type',payload);
        state.user_type = payload;
    },
    storename(state, payload) {
        // window.sessionStorage.setItem('store_name',payload);
        state.store_name = payload;
    },
    m_id(state, payload) {
        // window.sessionStorage.setItem('m_id',payload);
        state.m_id = payload;
    },
    p_id(state, payload) {
        window.sessionStorage.setItem('p_id', payload);
        state.p_id = payload;
    },
    u_id(state, payload) {
        // window.sessionStorage.setItem('u_id',payload);
        state.u_id = payload;
    },
    isbind(state, payload) {
        // window.sessionStorage.setItem('isbind',payload);
        state.isbind = payload;
    },
    phone(state, payload) {
        // window.sessionStorage.setItem('phone',payload);
        state.phone = payload;
    },
    goodstype(state, payload) {
        // window.sessionStorage.setItem('goodstype',payload);
        state.goodstype = payload;
    },
    vir_id(state, payload) {
        // window.sessionStorage.setItem('vir_id',payload);
        state.vir_id = payload;
    },
    sub_status(state, payload) {
        // window.sessionStorage.setItem('sub_status',payload);
        state.sub_status = payload;
    },
    sub_power(state, payload) {
        // window.sessionStorage.setItem('sub_power',payload);
        state.sub_power = payload;
    },
    real_name(state, payload) {
        // window.sessionStorage.setItem('name',payload);
        state.real_name = payload;
    },
    fid(state, payload) {
        // window.sessionStorage.setItem('fid',payload);
        state.fid = payload;
    },
    sub_id(state, payload) {
        // window.sessionStorage.setItem('sub_id',payload);
        state.sub_id = payload;
    },
    reg_time(state, payload) {
        // window.sessionStorage.setItem('reg_time',payload);
        state.reg_time = payload;
    },
    refresh(state, payload) {
        state.refresh = payload;
    },
    stock_remind(state, payload) {
        // window.sessionStorage.setItem('stock_remind',payload);
        state.stock_remind = payload;
    },
    a_s_show(state, payload) {
        state.a_s_show = payload;
    },
    activename(state, payload) {
        state.activename = payload;
    },
    lsxd_open(state, payload) {
        state.lsxd_goods_open = payload;
    },
    dataVal(state,payload){
        state.dataVal=payload;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}