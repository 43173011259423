const state = {
    islogin : window.sessionStorage.getItem('islogin') || false,
    msgnum : window.sessionStorage.getItem('msgnum') || '',
};

const getters = {
    getlogin_state(state){
        return state.islogin;
    },
    newmsg(state){
        return state.msgnum;
    }
}
const actions = {

};

const mutations = {
    login_info(state , payload){
        // window.sessionStorage.setItem('islogin',payload);
        state.islogin = payload;
    },
    msgnum(state , payload){
        window.sessionStorage.setItem('msgnum',payload);
        if(payload==0){
            // window.sessionStorage.setItem('msgnum','');
            state.msgnum = '';  
        }else{
            // window.sessionStorage.setItem('msgnum',payload);
            state.msgnum = payload; 
        }
        
    },
};

export default {
    namespaced : true,
    state,
    actions,
    mutations,
    getters,
}